import React from "react";

import { Paper, Typography, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import { GetLinkByAgreementId } from "../../../services/PaymentStripeServices";
import {
  editItemInLocalStorage,
  getItemFromLocalStorage,
} from "../../../services/localStorageService";
import "./PaymentProcessed.css";

const PaymentProcessed = () => {
  const [paymentStatus, setPaymentStatus] = React.useState("open");

  function handleGetAgreementIdFromUrl() {
    const url = window.location.href;
    const urlSplit = url.split("AgreementId=");
    return urlSplit[1];
  }

  function handleEditPaymentObjectFromLocalStorage(status) {
    let paymentObject = null;
    let keyOfObject = "";
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.includes("paymentCustomObject")) {
        paymentObject = getItemFromLocalStorage(key);
        keyOfObject = key;
      }
    }

    paymentObject[0].paymentStatus = status;
    editItemInLocalStorage(keyOfObject, paymentObject);
    const event = new CustomEvent("responseStripeCompleted", {
      detail: { status: status },
    });
    window.dispatchEvent(event);
  }

  const getParamFromUrl = (paramName) => {
    const url = window.location.href;
    const paramIndex = url.indexOf(paramName);
    return paramIndex !== -1
      ? url.substring(paramIndex + paramName.length)
      : null;
  };

  function handleValidateIfAgreementProductAndPaymentObjectExist() {
    let paymentObject = null;
    let keyOfObject = "";
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key.includes("paymentCustomObject")) {
        paymentObject = getItemFromLocalStorage(key);
        keyOfObject = key;
      }
    }

    const cart = getItemFromLocalStorage(keyOfObject);
    return paymentObject && cart ? true : false;
  }
  React.useEffect(() => {
    function GetPaymentStatus() {
      const AgreementId = handleGetAgreementIdFromUrl();

      GetLinkByAgreementId(AgreementId)
        .then((data) => {
          if (data.PaymentResponse.statusPayment !== "completed") {
            return;
          }
          setPaymentStatus(data.PaymentResponse.statusPayment);
          handleEditPaymentObjectFromLocalStorage("completed");
          clearInterval(getStatus);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    let maxAttempts = 7;
    const validateIfAgreementProductAndPaymentObjectExist =
      handleValidateIfAgreementProductAndPaymentObjectExist();
    if (!validateIfAgreementProductAndPaymentObjectExist) {
      setPaymentStatus("created");
      return;
    }
    const getStatus = setInterval(() => {
      if (maxAttempts !== 0) {
        GetPaymentStatus();
      } else {
        clearInterval(getStatus);
        setPaymentStatus("created");
        handleEditPaymentObjectFromLocalStorage("created");
      }
      maxAttempts--;
    }, 5000);
  }, []);

  return (
    <>
      {paymentStatus === "open" && (
        <>
          <Paper elevation={2} className="CarView-payment-processed-paper">
            <Grid
              container
              className="CarView-payment-processed-grid-container"
            >
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="CartView-payment-processed-grid-icon"
              >
                <CircularProgress
                  thickness={7}
                  className="CartView-circular-progress"
                />
              </Grid>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className="CartView-payment-processed-text-status">
                  {" "}
                  Payment in process{" "}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      {paymentStatus === "completed" && (
        <>
          <Paper elevation={2} className="CarView-payment-processed-paper">
            <Grid
              container
              className="CarView-payment-processed-grid-container"
            >
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="CartView-payment-processed-grid-icon"
              >
                <CheckIcon className="CartView-payment-processed-icon" />
              </Grid>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className="CartView-payment-processed-text-status">
                  ¡Payment Completed!
                </Typography>
                <Typography className="CartView-payment-processed-text-status-description">
                  Your payment has been processed successfully. In a period of
                  24 hours, you will receive an email with the confirmation of
                  your order.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}{" "}
      {paymentStatus === "created" && (
        <>
          <Paper elevation={2} className="CarView-payment-processed-paper">
            <Grid
              container
              className="CarView-payment-processed-grid-container"
            >
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="CartView-payment-processed-grid-icon"
              >
                <InfoIcon className="CartView-payment-processed-icon" />
              </Grid>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className="CartView-payment-processed-text-status">
                  ¡Payment Processed!
                </Typography>
                <Typography className="CartView-payment-processed-text-status-description">
                  Your payment has been processed, contact us to check the
                  status of your order.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
    </>
  );
};

export default PaymentProcessed;
